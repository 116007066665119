import React                  from 'react'
import { Link }               from 'gatsby'
import { withStyles }         from '@material-ui/core/styles'
import ErrorIcon              from '@material-ui/icons/Error'
import { amber, red }         from '@material-ui/core/colors'
import IconButton             from '@material-ui/core/IconButton'
import ExitToApp              from '@material-ui/icons/ExitToApp'
import SnackbarContent        from '@material-ui/core/SnackbarContent'
import WarningIcon            from '@material-ui/icons/Warning'

const variantIcon = {
  warning: WarningIcon,
  error: ErrorIcon,
}

const styles = (theme) => ({
  error: {
    backgroundColor: red[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default withStyles(styles)((props) => {
  const { onAction, variant, open, className, classes, message, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={
        (
          <span id="client-snackbar" className={classes.message}>
            <Icon className={`${classes.icon} ${classes.iconVariant}`} />
            {message}
          </span>
        )
      }
      action={[
        typeof onAction === 'string' ?
          <Link to={onAction} style={{ color: 'white' }} mr={8}>Reintentar</Link> : (
            <IconButton key="close" aria-label="close" color="inherit" onClick={onAction}>
              <ExitToApp className={classes.icon} />
            </IconButton>
          ),
      ]}
      {...other}
    />
  )
})
