import React                      from 'react'
import Typography                 from '@material-ui/core/Typography'
import Box                        from '@material-ui/core/Box'
import { makeStyles }             from '@material-ui/core/styles'
import PersonIcon                 from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(1),
    fontSize: 40,
  },
}))

export default function Person({ column }) {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="row"
      css={{ alignItems: 'center' }}
    >
      <PersonIcon className={classes.icon}/>
      <Box
        display="flex"
        flexDirection="column"
        css={{ alignItems: 'flex-start' }}
      >
        <Typography variant="body1" color="textSecondary" align="center">
          {column.cargo}
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center">
          {column.dni}
        </Typography>
      </Box>
    </Box>
  )
}
