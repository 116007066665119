import React            from 'react'
import { navigate }     from 'gatsby'
import CssBaseline      from '@material-ui/core/CssBaseline'
import { withStyles }   from '@material-ui/core/styles'
import Container        from '@material-ui/core/Container'
import Paper            from '@material-ui/core/Paper'
import Table            from '@material-ui/core/Table'
import TableBody        from '@material-ui/core/TableBody'
import TableCell        from '@material-ui/core/TableCell'
import TableHead        from '@material-ui/core/TableHead'
import TableRow         from '@material-ui/core/TableRow'
import Box              from '@material-ui/core/Box'
import Button           from '@material-ui/core/Button'
import ExitToApp        from '@material-ui/icons/ExitToApp'
import Add              from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'

import zipcelx          from 'zipcelx'

import Person           from './Person'
import SnackBar         from './SnackBar'
import Copyright        from '../Copyright'

import { logout } from '../../utils/auth'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  tableWrapper: {
    height: 650,
    overflow: 'auto',
  },
  submit: {
    margin: theme.spacing(3, 0),
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 35,
  },
  progress: {
    margin: theme.spacing(2),
  },
})

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

class Results extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: true,
      error: null,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.proposal = window.localStorage.bmw_proposal
      ? JSON.parse(window.localStorage.bmw_proposal)
      : {}
    this.userToken = window.localStorage.bmw_auth
      ? JSON.parse(window.localStorage.bmw_auth).token
      : ''
    this.fetchDataParallel(this.proposal)
  }

  getRows() {
    const { data } = this.state
    let keysUnion = []

    data.forEach((p) => { keysUnion = [...keysUnion, ...Object.keys(p.info)] })
    keysUnion = new Set(keysUnion)

    const rows = [...keysUnion].map((k) => this.createData(k, data))

    return rows
  }

  createData = (field, list_persons) => {
    let new_object = {}
    list_persons.forEach((elem, idx) => {
      new_object = { ...new_object, [`person${idx + 1}`]: elem.info[field] !== 'Nan' ? elem.info[field] : '-' }
    })
    return { field, ...new_object }
  }


  createColumns = (dataSource) => {
    let columns = [
      { id: 'field', label: '', minWidth: 200 },
    ]
    dataSource.forEach((elem, idx) => {
      columns = [...columns, { id: `person${idx + 1}`, dni: elem.dni, cargo: elem.cargo, csv: elem.csv, minWidth: 200 }]
    })
    return columns
  }

  generateBodies = (raw) => {
    const bodies = []
    const elements = []
    const body = {}
    const request_id = guid()
    Object.keys(raw).forEach((key) => {
      if (key === 'id') {
        body.proposal_id = raw[key].value
        body.request_id = request_id
      } else {
        const fieldGroupId = key.slice(-1)
        elements[fieldGroupId - 1] = { ...elements[fieldGroupId - 1], [key.slice(0, -1)]: raw[key].value }
      }
    })
    body.elements = elements
    body.elements.forEach((val) => {
      const newBody = {}
      newBody.proposal_id = body.proposal_id
      newBody.request_id = body.request_id
      newBody.elements = [val]
      bodies.push(newBody)
    })

    return bodies
  }

  fetchDataParallel(proposal) {
    const bodies = this.generateBodies(proposal)

    const request_array = []
    const array_data = []
    const array_error = []
    bodies.forEach((body) => {
      request_array.push(
        fetch(
          'https://1nedxaqo94.execute-api.eu-west-1.amazonaws.com/dev/ocr',
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Api-Key': 'WfhyFfDOzSTtUfldZlZH2UnHLgHYFWgZf5GMX110',
              'Authorization': this.userToken,
            },
          },
        ).then((response) => response.json())
          .then((res) => {
            if (typeof res === 'object' &&
              Object.prototype.hasOwnProperty.call(res, 'message')
            ) {
              this.setState({ error: res })
            } else if (Array.isArray(res)) {
              const filter_data = res.find((d) => typeof d.info !== 'string')
              if (filter_data) {
                array_data.push(filter_data)
              }
              const filter_error = res.find((d) => typeof d.info === 'string')
              if (filter_error) {
                array_error.push(filter_error)
              }
            }
          }),
      )
    })

    Promise.all(request_array).then(
      () => {
        this.setState({ isLoading: false, data: array_data, error: array_error })
      },
    )


  }

  export() {
    const { data } = this.state
    const fields = Object.keys(data[0].info)
    const header = ['Cargo'].concat(data.map((d) => d.cargo))
    const excel_rows = [[{ value: 'ID', type: 'string' }, { value: this.proposal.id.value, type: 'string' }], []]
    excel_rows.push(header.map((str) => ({ value: str, type: 'string' })))
    fields.forEach((field) => {
      let row = [{ value: field, type: 'string' }]
      row = row.concat(data.map((persona) => ({
        value: persona.info[field] === 'Nan' ? '' : persona.info[field].toString().trim(),
        type: 'string',
      })))
      excel_rows.push(row)
    })
    const config = {
      filename: this.proposal.id.value,
      sheet: {
        data: excel_rows,
      },
    }
    zipcelx(config)
  }

  renderWarningSnacks() {
    const { classes } = this.props
    const { error } = this.state
    if (error && Array.isArray(error)) {
      let snacks = []
      const onAction = '/proposal'
      const snackType = 'warning'
      snacks = error.map((e) => (
        <SnackBar
          variant={snackType}
          className={classes.margin}
          message={`Información no encontrada para ${e.dni}. (${e.info})`}
          onAction={onAction}
        />
      ))
      return (
        <div>
          <Box
            display="flex"
            flexDirection="column"
            css={{ alignItems: 'center' }}
          >
            {snacks}
          </Box>
        </div>
      )
    }
    return null
  }

  renderContent() {
    const { classes } = this.props
    const { data, isLoading, error } = this.state

    if (error && !Array.isArray(error)) {
      const onAction = () => logout(() => navigate('/login'))
      const snackType = 'error'
      const code = Object.prototype.hasOwnProperty.call(error, 'code') ? error.code : ''
      let snackMessage = 'La sesión ha caducado. Por favor, vuelve a iniciar sesión'
      if (code && code.toString().slice(0, 1) === '5') snackMessage = 'Se ha producido un error, vuelve a intentarlo más tarde'
      return (
        <Box
          display="flex"
          flexDirection="column"
          css={{ alignItems: 'center' }}
        >
          <SnackBar
            variant={snackType}
            className={classes.margin}
            message={snackMessage}
            onAction={onAction}
          />
        </Box>
      )
    }

    if (data.length > 0 && !isLoading) {
      return (
        <div>
          { this.renderWarningSnacks() }
          <Box
            display="flex"
            flexDirection="row"
            css={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Button onClick={() => navigate('/proposal')}>
              <Add className={classes.icon} />
              Nueva propuesta
            </Button>
            <Button onClick={() => logout(() => navigate('/login'))}>
              <ExitToApp className={classes.icon} />
              Cerrar sesión
            </Button>
          </Box>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {this.createColumns(data).map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label || column.label === '' ? column.label : (<Person column={column} />)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getRows().map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.field}>
                      {this.createColumns(data).map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            css={{ alignItems: 'center' }}
          >
            <Button
              onClick={() => this.export()}
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Exportar
            </Button>
          </Box>
        </div>
      )
    }

    if (isLoading) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          css={{ alignItems: 'center' }}
        >
          <CircularProgress className={classes.progress} />
        </Box>
      )
    }

    return this.renderWarningSnacks() || (<p>Ha ocurrido un error inesperado</p>)
  }

  render() {

    return (
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        { this.renderContent() }
        <Copyright />
      </Container>
    )
  }

}

export default withStyles(styles)(Results)
